import { Button, Image, Input, Modal, Table, Upload } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  addCastToMovie,
  addCastToSeries,
  deleteEpisode,
  deleteMovie,
  deleteSeries,
  updateEpisode,
  updateMovie,
  updateSeries,
} from "../../utils/api";

import { isEnable } from "../common/IsEnabled";
import useSearch from "../common/useSearch";

const ContentTable = ({
  data,
  loading,
  episodes,
  setUpdate,
  setModal2,
  messageApi,
  reload,
  series,
  id,
}) => {
  const search = useSearch();
  const [loading3, setLoading3] = React.useState(false);
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const [cast, setCast] = React.useState({});
  const [castId, setCastId] = React.useState("");
  const [loading2, setLoading2] = React.useState(false);
  const addCast = async () => {
    setLoading2(true);
    const formData = new FormData();
    formData.append("image", cast.image);
    formData.append("name", cast.name);
    formData.append("role", cast.role);
    const [success, response] = series
      ? await addCastToSeries(castId, formData)
      : await addCastToMovie(castId, formData);
    if (success) {
      messageApi.success("Cast added successfully");
      setModal(false);
      reload();
    }
    setLoading2(false);
  };
  const url = !series
    ? [
        {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
        },
        {
          title: "Url",
          dataIndex: "url",
          key: "url",
          render: (text, record) => (
            <a href={text} target="_blank" rel="noreferrer">
              Link
            </a>
          ),
        },
      ]
    : [
        {
          title: "Episode",
          dataIndex: "episode",
          key: "episode",
          render: (text, record) => (
            <Button
              type="primary"
              onClick={() => {
                navigate(`/episodes/${record._id}`);
              }}
            >
              Episodes
            </Button>
          ),
        },
      ];

  const [tableParams, setTableParams] = React.useState({
    current: 1,
    pageSize: 10,
  });

  const url2 = !episodes
    ? [
        {
          title: "Release Year",
          dataIndex: "releaseYear",
          key: "releaseYear",
        },
        {
          title: "Slug",
          dataIndex: "slug",
          key: "slug",
        },

        {
          title: "Rating",
          dataIndex: "rating",
          key: "rating",
        },

        {
          title: "Language",
          dataIndex: "language",
          key: "language",
        },
        {
          title: "Genres",
          dataIndex: "genres",
          key: "genres",
          render: (text, record) =>
            text?.map((item) => <span>{item.name}, </span>),
        },
        {
          title: "Trailer",
          dataIndex: "trailer",
          key: "trailer",
          render: (text, record) => (
            <a href={text} target="_blank" rel="noreferrer">
              Link
            </a>
          ),
        },
        {
          title: "Cast",
          dataIndex: "cast",
          key: "cast",
          render: (text, record) =>
            text?.map((item) => <span>{item.name}, </span>),
        },
      ]
    : [];

  return (
    <>
      <Table
        rowKey={(record) => record._id}
        scroll={{ x: 1000 }}
        dataSource={data}
        pagination={{
          ...tableParams,
          total: data?.length,
        }}
        onChange={(pagination) => {
          setTableParams(pagination);
        }}
        loading={loading}
        columns={[
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
              <div className="col2">
                <Button
                  type="primary"
                  onClick={() => {
                    setModal2(true);
                    setUpdate(record);
                  }}
                >
                  Update
                </Button>
                <Button
                  loading={loading3}
                  type="primary"
                  danger={record.isEnable}
                  onClick={async () => {
                    setLoading3(true);
                    const data = {
                      isEnable: !record.isEnable,
                    };
                    const [success, response] = series
                      ? await updateSeries(record._id, data)
                      : episodes
                      ? await updateEpisode({
                          ...data,
                          seriesId: id,
                        })
                      : await updateMovie(record._id, data);
                    if (success) {
                      messageApi.success(" updated successfully");
                      reload();
                    }
                    setLoading3(false);
                  }}
                >
                  {record.isEnable ? "Disable" : "Enable"}
                </Button>
                {!episodes && (
                  <>
                    <Button
                      type="primary"
                      onClick={() => {
                        setModal(true);
                        setCastId(record._id);
                      }}
                    >
                      Add Cast
                    </Button>
                  </>
                )}
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: "Are you sure you want to delete this?",
                      onOk: async () => {
                        const [success, response] = series
                          ? await deleteSeries(record._id)
                          : episodes
                          ? await deleteEpisode({
                              seriesId: id,
                              episodeId: record._id,
                            })
                          : await deleteMovie(record._id);
                        if (success) {
                          messageApi.success(`
                        ${
                          series ? "Series" : episodes ? "Episode" : "Movie"
                        } deleted successfully`);
                          reload();
                        }
                      },
                    });
                  }}
                >
                  Delete
                </Button>
              </div>
            ),
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...search("name"),
          },
          isEnable,
          {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text, record) => (
              <div style={{ width: 300, overflow: "hidden" }}>
                {text.slice(0, 100)}
              </div>
            ),
          },
          ...url,
          ...url2,
          {
            title: "Horizonal-Banner",
            dataIndex: "horizontalBanner",
            key: "horizonalBanner",
            render: (text, record) => (
              <Image
                src={text}
                width={100}
                height={100}
                alt="horizonalBanner"
              />
            ),
          },
          {
            title: "Vertical-Banner",
            dataIndex: "verticalBanner",
            key: "verticalBanner",
            render: (text, record) => (
              <Image src={text} width={100} height={100} alt="verticalBanner" />
            ),
          },
          {
            title: "Long-Vertical-Banner",
            dataIndex: "longVerticalBanner",
            key: "longVerticalBanner",
            render: (text, record) => (
              <Image
                src={text}
                width={100}
                height={100}
                alt="longVerticalBanner"
              />
            ),
          },
        ]}
      />
      <Modal
        open={modal}
        onCancel={() => setModal(false)}
        title="Add Cast"
        onOk={addCast}
        confirmLoading={loading2}
      >
        <div className="col2">
          <Input
            placeholder="Name"
            onChange={(e) => {
              setCast((prev) => ({
                ...prev,
                name: e.target.value,
              }));
            }}
          />
          <Input
            placeholder="Role"
            onChange={(e) => {
              setCast((prev) => ({
                ...prev,
                role: e.target.value,
              }));
            }}
          />
          <Upload
            onChange={(info) => {
              setCast((prev) => ({
                ...prev,
                image: info.file.originFileObj,
              }));
            }}
          >
            <Button>Upload Image</Button>
          </Upload>
        </div>
      </Modal>
    </>
  );
};

export default ContentTable;
